import { api } from '../config/api';

export async function apiRequest<ReturnValue = void>(url: string, request: RequestInit = {}): Promise<ReturnValue> {
    let userIdString = window.localStorage.getItem('user');
    userIdString = userIdString ? JSON.parse(userIdString) : 0;

    const result = await fetch(`${api.BACKEND_DOMAIN}/${url}`, {
        ...request,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer <my token>',
            'x-user-id': userIdString
        }
    });
    const data = await result.json();
    return data && data.data ? data.data : data;
}
