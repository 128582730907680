import * as Apollo from '@apollo/client';
import { useModalResponse } from './useModalResponse';

type ApolloUseMutation<TData, TVariables> = (
    options?: Apollo.MutationHookOptions<TData, TVariables>
) => Apollo.MutationTuple<TData, TVariables>;

type Extract<T> = T extends (
    baseOptions?: Apollo.MutationHookOptions<infer MutationData, infer MutationVariables>
) => [Apollo.MutationFunction<infer MutationData, infer MutationVariables>, Apollo.MutationResult<infer MutationData>]
    ? { TData: MutationData; TVariables: MutationVariables }
    : { TMutationData: any; TVariables: any };

/**
 * perform data mutation based on modal requests
 *
 * Usage:
 *
 * ```ts
 * const [modal, mutate, mutationResult] = useModalMutation<InputData, typeof useGraphQlMutation>(
 *      useGraphQlMutation, (
 *      inputData) => {
 *          variables: {
 *              queryId: "1"
 *              inputData
 *          }
 *      })
 *
 * ...
 *
 * <Button onClick={mutate} />
 * <Modal isOpen={modal.isOpen} onClose={modal.closeOrSubmit}>
 *   <EditForm onSubmit={modal.closeOrSubmit} />
 * </Modal>
 * ```
 */
export function useModalMutation<
    InputData,
    UseMutation extends ApolloUseMutation<TData, TVariables>,
    TData = Extract<UseMutation>['TData'],
    TVariables = Extract<UseMutation>['TVariables']
>(useMutation: UseMutation, getOptions: (data: InputData) => Apollo.MutationFunctionOptions<TData, TVariables>) {
    const [isOpen, closeOrSubmit, requestData] = useModalResponse<InputData>();
    const [mutate, result] = useMutation();
    async function runMutation() {
        const data = await requestData();
        if (data) {
            return mutate(getOptions(data));
        }
        return undefined;
    }
    return [{ isOpen, closeOrSubmit }, runMutation, result] as const;
}
