import { CSSProperties, useState } from 'react';
import { Button, CheckIcon, Group, Radio, Stack, Stepper, Text, Title } from '@mantine/core';
import { getConsent, sendConsent, ConsentData } from '../api';
import { useLoaderData } from 'react-router-dom';
import { InterviewJourney } from '../userpanel/api';

export const consentLoader = async ({ params }) => {
    return getConsent(params.ticketId);
};

const StepData = [
    {
        id: 'username',
        stepTitle: 'Name',
        content: [],
        question: 'Die Einwilligungserklärung wird ausgeführt von',
        options: [
            {
                id: 'username:confirm'
            },
            {
                id: 'username:decline',
                label: 'Nein, das bin ich NICHT!'
            }
        ]
    },
    {
        id: 'consent',
        stepTitle: 'Einverständnis',
        content: [
            'Ich bestätige, dass meine Teilnahme an der Studie der leefs CX GmbH, freiwillig ist und ich habe verstanden, dass ich meine Teilnahme jederzeit beenden kann.',
            'Ich bin damit einverstanden, dass meine vorgenannten Daten und meine Aussagen in anonymisierter Form zu internen Forschungs- und Auswertungszwecken innerhalb der leefs CX GmbH und deren Vertragspartnern erhoben, gespeichert und verwertet werden. Dies geschieht unter Einhaltung der Grundsätze zur Datenspeicherung und -verarbeitung nach Artikel 5 der DSGVO. Durch die Anonymisierung Ihrer Daten, kann die leefs CX GmbH später keine Rückschlüsse zu Ihrer Person sowie zu Ihren Kontaktdaten ziehen und deshalb kann niemand erkennen, von welcher Person die gemachten Angaben und Aussagen stammen.',
            'Ich bin damit einverstanden, dass das Interview per Video (Bild und Ton) aufgezeichnet wird und innerhalb der leefs CX GmbH und deren Vertragspartnern zu Auswertungszwecken für bis zu 6 Monate gespeichert und verwertet wird. '
        ],
        question: 'Bitte bestätigen Sie Ihre Einwilligung',
        options: [
            {
                id: 'consent:confirm',
                label: 'Hiermit bestätige ich meine Einwilligung zur Teilnahme.'
            },
            {
                id: 'consent:decline',
                label: 'Ich gebe die Einwilligung NICHT.'
            }
        ],
        required: true
    },
    {
        id: 'nda',
        stepTitle: 'Geheimhaltung',
        question: 'Bitte bestätigen Sie Ihre Einwilligung durch die Auswahl der entsprechenden Antwort',
        content: [
            'Mir ist bewusst, dass ich durch meine Teilnahme an der Studie eventuell in den Besitz von vertraulichen Informationen gelangen werde. Diese Informationen sind technischer und wirtschaftlicher Art. Mit meiner Unterschrift bestätige ich, dass ich keine vertraulichen Informationen (insbesondere über die Produkte, die heute getestet werden, sowie über die Studie an sich) an Dritte (Personen und/oder Unternehmen) weitergeben werde. '
        ],
        options: [
            {
                id: 'nda:confirm',
                label: 'Hiermit bestätige ich meine Einwilligung zur Geheimhaltung.'
            },
            {
                id: 'nda:decline',
                label: 'Ich gebe meine Einwilligung zur Geheimhaltung NICHT.'
            }
        ],
        required: true
    },
    {
        id: 'property',
        stepTitle: 'Eigentumsrecht',
        question: 'Bitte bestätigen Sie Ihre Einwilligung durch die Auswahl der entsprechenden Antwort',
        content: [
            'Hiermit räume ich der leefs CX GmbH und deren Vertragspartnern ein exklusives, unwiderrufliches, übertragbares, zeitlich und örtlich nicht begrenztes Nutzungsrecht an allen Arbeitsergebnissen (Ideen, Erfindungen, Konzepte) ein, die im Rahmen der Studie durch mich zustande gekommen sind.',
            'Das Nutzungsrecht der leefs CX GmbH und deren Vertragspartnern schließt das Recht ein, die Arbeitsergebnisse umzuarbeiten, zu vervielfältigen, zu ändern und zu erweitern und die hierdurch geschaffenen Leistungsergebnisse in der gleichen Weise wie die ursprünglichen Arbeitsergebnisse zu nutzen. Ferner schließt dies das Vertriebsrecht, das Recht zur Unterlizenzierung, zur Weitergabe an Dritte und zur Veröffentlichung ein.'
        ],
        options: [
            {
                id: 'property:confirm',
                label: 'Hiermit bestätige ich meine Zustimmung zur Eigentumsrechtvergabe.'
            },
            {
                id: 'property:decline',
                label: 'Ich gebe NICHT die Zustimmung zur Eigentumsrechtvergabe.'
            }
        ],
        required: true
    },
    {
        id: 'panelMember',
        stepTitle: 'Absenden',
        content: [],
        question:
            'Ich bin damit einverstanden, dass ich auch in Zukunft für weitere Interview-Anfragen kontaktiert werden kann',
        options: [
            {
                id: 'panelMember:confirm',
                label: 'Ja, gerne!'
            },
            {
                id: 'panelMember:decline',
                label: 'Nein, danke!'
            }
        ]
    }
];

const formData: Record<string, { required: boolean; value?: 'confirm' | 'decline' | boolean }> = {};
StepData.forEach((step) => {
    formData[step.id] = {
        required: step.required === true
    };
});

const contentWrapperStyles: CSSProperties = {
    minHeight: '90vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
};

const contentStyles: CSSProperties = {
    maxWidth: 720
};

export function Consent({ defaultData = JSON.parse(JSON.stringify(formData)) }) {
    const interview = useLoaderData() as InterviewJourney;
    const [active, setActive] = useState(0);
    const [welcome, setWelcome] = useState(true);
    const nextStep = () => setActive((current) => (current < StepData.length ? current + 1 : current));
    const [data, setData] = useState<typeof formData>(defaultData);

    console.log('data', data);

    StepData.forEach((step) => {
        if (step.id === 'username') {
            const nameOption = step.options.find((o) => o.id === 'username:confirm');
            if (nameOption) {
                nameOption.label = `${interview.participant.surname}, ${interview.participant.lastname}`;
            }
        }
    });

    if (interview.consent === 'accepted') {
        return (
            <div className="page page--consent">
                <div style={contentWrapperStyles}>
                    <div style={contentStyles}>
                        <p>Wir haben Ihre Einwilligung bereits erhalten. Vielen Dank!</p>
                    </div>
                </div>
            </div>
        );
    }

    if (!['declined', 'pending'].includes(interview.consent)) {
        return (
            <div className="page page--consent">
                <div style={contentWrapperStyles}>
                    <div style={contentStyles}>
                        <p>Hier gibt es nichts zu sehen</p>
                    </div>
                </div>
            </div>
        );
    }

    async function onSubmit() {
        const payload: ConsentData = {
            ...(data as unknown as ConsentData),
            consentId: interview.consent_ticket,
            consentDate: new Date().toISOString(),
            user: {
                surname: interview.participant.surname,
                lastname: interview.participant.lastname,
                email: interview.participant.email
            }
        };
        await sendConsent(interview.consent_ticket, payload);
        console.log('on submit', data);
        nextStep();
    }

    if (welcome) {
        return (
            <div className="page page--consent">
                <div style={contentWrapperStyles}>
                    <div style={contentStyles}>
                        <p>{interview.project.consent_intro}</p>
                        <p>
                            In diesem Formular erteilen Sie nochmal förmlich die Einwilligung zur Teilnahme. Zusätzlich
                            wird auch die Zustimmung zur Ton- und Videoaufnahme, Datenverarbeitung und Geheimhaltung
                            erteilt. Für Details klicken Sie bitte auf "Weiter".
                        </p>
                        <Group justify="center" mt="xl">
                            <Button onClick={() => setWelcome(false)}>weiter</Button>
                        </Group>
                    </div>
                </div>
            </div>
        );
    }

    if (active === StepData.length) {
        return (
            <div className="page page--consent">
                <div style={contentWrapperStyles}>
                    <div style={contentStyles}>
                        <p>Vielen Dank für Ihre Einwilligung</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page page--consent" style={{ width: 640, margin: '0 auto', paddingTop: '1em' }}>
            <Stepper size="sm" active={active} onStepClick={setActive}>
                {StepData.map((step, index) => (
                    <Stepper.Step key={step.id} label={active === index ? step.stepTitle : ''} description="">
                        <div style={contentWrapperStyles}>
                            <div style={contentStyles}>
                                <Title
                                    order={5}
                                    style={{
                                        padding: '1em 0'
                                    }}
                                >
                                    {index + 1}. {step.stepTitle}
                                </Title>

                                {step.content.map((c, index) => (
                                    <p key={`${step.id}:content:${index}`}>{c}</p>
                                ))}
                                <div style={{ paddingTop: '1em' }}>
                                    <Radio.Group
                                        name={step.id}
                                        label={step.question}
                                        description={step.required ? 'Dies ist eine Pflichtangabe.' : null}
                                        withAsterisk={step.required}
                                        onChange={(key) => {
                                            const [id, value] = key.split(':');
                                            const nextData = { ...data };
                                            nextData[id] = { ...nextData[id] };
                                            nextData[id].value = value as 'confirm' | 'decline';
                                            setData(nextData);
                                        }}
                                    >
                                        <Stack style={{ paddingTop: '1em' }}>
                                            {step.options.map((option) => (
                                                <Radio.Card
                                                    key={option.id}
                                                    name={option.id}
                                                    value={option.id}
                                                    style={{
                                                        padding: '0.5em 1em',
                                                        alignSelf: 'start',
                                                        width: 'auto',
                                                        borderRadius: '1.25em'
                                                    }}
                                                >
                                                    <Group wrap="nowrap" align="center">
                                                        <Radio.Indicator icon={CheckIcon} />
                                                        <Text>{option.label}</Text>
                                                    </Group>
                                                </Radio.Card>
                                            ))}
                                        </Stack>
                                    </Radio.Group>
                                </div>
                                <Group justify="center" mt="xl">
                                    {active === StepData.length - 1 ? (
                                        <Button type="submit" onClick={() => onSubmit()}>
                                            senden
                                        </Button>
                                    ) : (
                                        <Button onClick={nextStep}>weiter</Button>
                                    )}
                                </Group>
                            </div>
                        </div>
                    </Stepper.Step>
                ))}
                <Stepper.Completed>Completed, click back button to get to previous step</Stepper.Completed>
            </Stepper>
        </div>
    );
}
