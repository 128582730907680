import classNames from 'classnames';
import styles from './filter-tag.module.scss';
import { CSSProperties, ReactNode } from 'react';

export type FilterTagProps = {
    label?: string;
    values?: ReactNode[];
    style?: CSSProperties;
};

export function FilterTag({ label, values = [], style }: FilterTagProps) {
    return (
        <div className={classNames('filter-tag', styles['filter-tag'])} style={style}>
            {label ? <label>{label}</label> : null}
            {values.map((v, idx) => (
                <span key={idx}>{v}</span>
            ))}
        </div>
    );
}
