import { Button, Center, Modal } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditPanelForm } from '../components/EditPanelForm';
import { GetPanelsDocument, PanelInput, useCreatePanelMutation, useGetPanelsQuery } from '../userpanel/api';
import { useModalMutation } from '../components/useModalMutation';
import { Brick } from '../components/brick/Brick';
import { SimpleTable } from '../components/simpletable/SimpleTable';

export function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: panelData } = useGetPanelsQuery();
    const panels = panelData?.panels ?? [];

    const [panelModal, createPanel] = useModalMutation<PanelInput, typeof useCreatePanelMutation>(
        useCreatePanelMutation,
        (panelData) => ({
            variables: {
                panelData
            },
            refetchQueries: [
                {
                    query: GetPanelsDocument
                }
            ]
        })
    );

    return (
        <div className="page page--home">
            <Brick variant="solid">
                <Brick.Header>
                    <span>{t('panels')}</span>
                    <div style={{ flexGrow: 1, textAlign: 'right' }}>
                        <Button variant="transparent" onClick={createPanel}>
                            {t('createPanel')}
                        </Button>
                    </div>
                </Brick.Header>
                <Brick.Content>
                    <SimpleTable alternate>
                        {panels.length === 0 ? (
                            <SimpleTable.Row>
                                <Center style={{ flexGrow: 1 }}>{t('noPanelsAvailable')}</Center>
                                <Center>
                                    <Button onClick={createPanel}>{t('createPanel')}</Button>
                                </Center>
                            </SimpleTable.Row>
                        ) : (
                            panels.map((panel) => (
                                <SimpleTable.Row key={panel.id} onClick={() => navigate(`panel/${panel.id}`)}>
                                    <SimpleTable.Cell title={t('panelTitle')}>{panel.title}</SimpleTable.Cell>
                                    <SimpleTable.Cell title={t('participants')}>
                                        {panel.participantCount}
                                    </SimpleTable.Cell>
                                    <SimpleTable.Cell title={t('projects')}>{panel.projectCount}</SimpleTable.Cell>
                                </SimpleTable.Row>
                            ))
                        )}
                    </SimpleTable>
                </Brick.Content>
                <Modal opened={panelModal.isOpen} onClose={panelModal.closeOrSubmit} title={t('create new panel')}>
                    <EditPanelForm onSubmit={panelModal.closeOrSubmit} />
                </Modal>
            </Brick>
        </div>
    );
}
