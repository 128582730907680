import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: { input: any; output: any };
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: { input: any; output: any };
};

/** Approval states for interview, incentive, or consent */
export enum ApprovalState {
    Accepted = 'accepted',
    Declined = 'declined',
    Pending = 'pending'
}

export type Audit = {
    __typename?: 'Audit';
    created_at: Scalars['DateTime']['output'];
    entity?: Maybe<Scalars['String']['output']>;
    entityId?: Maybe<Scalars['Float']['output']>;
    id: Scalars['ID']['output'];
    ip?: Maybe<Scalars['String']['output']>;
    message: Scalars['String']['output'];
    participantId?: Maybe<Scalars['Float']['output']>;
    payload?: Maybe<Scalars['JSONObject']['output']>;
    relatedEntity?: Maybe<Scalars['String']['output']>;
    relatedEntityId?: Maybe<Scalars['Float']['output']>;
    type: Scalars['String']['output'];
    typeId?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['Float']['output']>;
};

export type Booking = {
    __typename?: 'Booking';
    attendeeParticipants: Array<Participant>;
    attendeeUsers: Array<User>;
    created_at: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    end?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    interviewJourney?: Maybe<InterviewJourney>;
    organizer: User;
    sequence: Scalars['Int']['output'];
    start?: Maybe<Scalars['DateTime']['output']>;
    status?: Maybe<BookingStatus>;
    title?: Maybe<Scalars['String']['output']>;
    uid?: Maybe<Scalars['String']['output']>;
    videoCallUrl?: Maybe<Scalars['String']['output']>;
};

/** Possible statuses of a booking */
export enum BookingStatus {
    Cancelled = 'Cancelled',
    Confirmed = 'Confirmed',
    Tentative = 'Tentative'
}

export type CreateInterviewJourneyInput = {
    participant: Scalars['Int']['input'];
    project: Scalars['Int']['input'];
};

export type DeleteResponse = {
    __typename?: 'DeleteResponse';
    success: Scalars['Boolean']['output'];
};

export type InterviewJourney = {
    __typename?: 'InterviewJourney';
    booking?: Maybe<Booking>;
    consent?: Maybe<ApprovalState>;
    /** Consent log as JSON object */
    consent_log?: Maybe<Scalars['String']['output']>;
    /** Mail ID for consent in mail-service */
    consent_mail?: Maybe<Scalars['String']['output']>;
    consent_ticket: Scalars['String']['output'];
    /** Entity creation timestamp */
    created_at?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    incentive?: Maybe<ApprovalState>;
    interview?: Maybe<ApprovalState>;
    /** Mail ID in mail-service */
    interview_mail?: Maybe<Scalars['String']['output']>;
    interview_status: Scalars['String']['output'];
    /** Reference to Participant entity */
    participant: Participant;
    project: Project;
    /** Current interview-journey workflow state */
    workflow: Scalars['String']['output'];
};

export type InterviewWorkflowInput = {
    attendeeParticipants?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
    attendeeUsers?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
    consent?: InputMaybe<ApprovalState>;
    description?: InputMaybe<Scalars['String']['input']>;
    end?: InputMaybe<Scalars['String']['input']>;
    htmlContent?: InputMaybe<Scalars['String']['input']>;
    incentive?: InputMaybe<ApprovalState>;
    interview?: InputMaybe<ApprovalState>;
    interviewJourneyId?: InputMaybe<Scalars['String']['input']>;
    interview_status?: InputMaybe<ApprovalState>;
    organizer?: InputMaybe<Scalars['JSONObject']['input']>;
    start?: InputMaybe<Scalars['String']['input']>;
    subject?: InputMaybe<Scalars['String']['input']>;
    textContent?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    to?: InputMaybe<Array<MailUser>>;
    videoCallUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MailUser = {
    email: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type Mutation = {
    __typename?: 'Mutation';
    anonymizeParticipant: Participant;
    assignParticipants: Project;
    createInterviewJourney: InterviewJourney;
    createPanel: Panel;
    createParticipant: Participant;
    createProject: Project;
    createUser: User;
    deleteInterviewJourney: InterviewJourney;
    deleteParticipant: DeleteResponse;
    triggerInterviewWorkflow: InterviewJourney;
    triggerOnboardingWorkflow: Participant;
    updatePanel: Panel;
    updateParticipant: Participant;
    updateProject: Project;
    updateUser: User;
};

export type MutationAnonymizeParticipantArgs = {
    panelId: Scalars['ID']['input'];
    participantId: Scalars['ID']['input'];
};

export type MutationAssignParticipantsArgs = {
    participants: Array<Scalars['ID']['input']>;
    projectId: Scalars['ID']['input'];
};

export type MutationCreateInterviewJourneyArgs = {
    createInterviewJourneyInput: CreateInterviewJourneyInput;
};

export type MutationCreatePanelArgs = {
    panelData: PanelInput;
};

export type MutationCreateParticipantArgs = {
    panelId: Scalars['String']['input'];
    participantData: ParticipantInput;
    projectId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateProjectArgs = {
    panelId: Scalars['ID']['input'];
    projectData: ProjectInput;
};

export type MutationCreateUserArgs = {
    userData: UserInput;
};

export type MutationDeleteInterviewJourneyArgs = {
    interviewJourneyId: Scalars['ID']['input'];
};

export type MutationDeleteParticipantArgs = {
    panelId: Scalars['ID']['input'];
    participantId: Scalars['ID']['input'];
};

export type MutationTriggerInterviewWorkflowArgs = {
    eventName: Scalars['String']['input'];
    interviewJourneyId: Scalars['ID']['input'];
    workflowData: InterviewWorkflowInput;
};

export type MutationTriggerOnboardingWorkflowArgs = {
    eventName: Scalars['String']['input'];
    participantId: Scalars['ID']['input'];
    workflowData: OnboardingWorkflowInput;
};

export type MutationUpdatePanelArgs = {
    panelData: PanelInput;
    panelId: Scalars['String']['input'];
};

export type MutationUpdateParticipantArgs = {
    participantData: ParticipantInput;
    participantId: Scalars['ID']['input'];
};

export type MutationUpdateProjectArgs = {
    projectData: ProjectInput;
    projectId: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
    userData: UserInput;
    userId: Scalars['String']['input'];
};

export type OnboardingWorkflowInput = {
    consent?: InputMaybe<ApprovalState>;
    consent_interviews?: InputMaybe<ApprovalState>;
    htmlContent?: InputMaybe<Scalars['String']['input']>;
    interviewJourneyId?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['JSONObject']['input']>;
    subject?: InputMaybe<Scalars['String']['input']>;
    textContent?: InputMaybe<Scalars['String']['input']>;
    to?: InputMaybe<Array<MailUser>>;
};

export type Panel = {
    __typename?: 'Panel';
    created_at: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    participantCount: Scalars['Int']['output'];
    participants: Array<Participant>;
    projectCount: Scalars['Int']['output'];
    projects: Array<Project>;
    screening_document?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
};

export type PanelInput = {
    screening_document?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
};

export type Participant = {
    __typename?: 'Participant';
    anonymous: Scalars['Boolean']['output'];
    audit: Array<Audit>;
    bookings: Array<Booking>;
    consent_mail?: Maybe<Scalars['String']['output']>;
    consent_panel?: Maybe<ApprovalState>;
    consent_ticket: Scalars['String']['output'];
    created_at: Scalars['DateTime']['output'];
    email: Scalars['String']['output'];
    hasActiveInterview?: Maybe<Scalars['Boolean']['output']>;
    hasInterview?: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    interviewJourneyCount?: Maybe<Scalars['Int']['output']>;
    interviewJourneys: Array<InterviewJourney>;
    interview_mail?: Maybe<Scalars['String']['output']>;
    lastname: Scalars['String']['output'];
    notes?: Maybe<Scalars['JSONObject']['output']>;
    panel: Panel;
    phone: Scalars['String']['output'];
    screening?: Maybe<ApprovalState>;
    surname: Scalars['String']['output'];
    workflow: Scalars['String']['output'];
};

export type ParticipantInput = {
    email: Scalars['String']['input'];
    lastname: Scalars['String']['input'];
    notes?: InputMaybe<Scalars['JSONObject']['input']>;
    phone: Scalars['String']['input'];
    surname: Scalars['String']['input'];
};

export type Project = {
    __typename?: 'Project';
    consent_intro?: Maybe<Scalars['String']['output']>;
    created_at: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['JSONObject']['output']>;
    end_date: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    interviewJourneys: Array<InterviewJourney>;
    panel: Panel;
    participantCount: Scalars['Int']['output'];
    screening_document?: Maybe<Scalars['String']['output']>;
    start_date: Scalars['String']['output'];
    title: Scalars['String']['output'];
    videoCallUrl?: Maybe<Scalars['String']['output']>;
};

export type ProjectInput = {
    consent_intro?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['JSONObject']['input']>;
    end_date?: InputMaybe<Scalars['String']['input']>;
    screening_document?: InputMaybe<Scalars['String']['input']>;
    start_date?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
    videoCallUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
    __typename?: 'Query';
    audit: Array<Audit>;
    booking: Booking;
    interviewJourneys: Array<InterviewJourney>;
    interviewJourneysByProject: Array<InterviewJourney>;
    panel: Panel;
    panels: Array<Panel>;
    participant: Participant;
    project: Project;
    projects: Array<Project>;
    users: Array<User>;
};

export type QueryAuditArgs = {
    entity: Scalars['String']['input'];
    entityId: Scalars['String']['input'];
};

export type QueryBookingArgs = {
    bookingId: Scalars['ID']['input'];
};

export type QueryInterviewJourneysByProjectArgs = {
    projectId: Scalars['ID']['input'];
};

export type QueryPanelArgs = {
    panelId: Scalars['ID']['input'];
};

export type QueryParticipantArgs = {
    participantId: Scalars['ID']['input'];
};

export type QueryProjectArgs = {
    projectId: Scalars['ID']['input'];
};

export type QueryProjectsArgs = {
    panelId: Scalars['ID']['input'];
};

export type User = {
    __typename?: 'User';
    bookings: Array<Booking>;
    color?: Maybe<Scalars['String']['output']>;
    created_at: Scalars['DateTime']['output'];
    email: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    lastname: Scalars['String']['output'];
    organizers: Array<Booking>;
    surname: Scalars['String']['output'];
    title: Scalars['String']['output'];
    type: UserType;
};

export type UserInput = {
    color?: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    id?: InputMaybe<Scalars['ID']['input']>;
    lastname: Scalars['String']['input'];
    surname: Scalars['String']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<UserType>;
};

/** Category of user, which has an impact on shared personal data */
export enum UserType {
    Admin = 'admin',
    Client = 'client'
}

export type AuditPropertiesFragment = {
    __typename?: 'Audit';
    created_at: any;
    entity?: string | null;
    entityId?: number | null;
    id: string;
    message: string;
    participantId?: number | null;
    relatedEntity?: string | null;
    relatedEntityId?: number | null;
    type: string;
    typeId?: string | null;
    userId?: number | null;
};

export type BookingPropertiesFragment = {
    __typename?: 'Booking';
    id: string;
    uid?: string | null;
    sequence: number;
    title?: string | null;
    description?: string | null;
    start?: any | null;
    end?: any | null;
    status?: BookingStatus | null;
    videoCallUrl?: string | null;
    created_at: any;
};

export type BookingRelationsFragment = {
    __typename?: 'Booking';
    organizer: {
        __typename?: 'User';
        color?: string | null;
        created_at: any;
        email: string;
        id: string;
        lastname: string;
        surname: string;
        title: string;
        type: UserType;
    };
    attendeeUsers: Array<{
        __typename?: 'User';
        color?: string | null;
        created_at: any;
        email: string;
        id: string;
        lastname: string;
        surname: string;
        title: string;
        type: UserType;
    }>;
    attendeeParticipants: Array<{
        __typename?: 'Participant';
        id: string;
        surname: string;
        lastname: string;
        email: string;
        phone: string;
    }>;
    interviewJourney?: { __typename?: 'InterviewJourney'; id: string } | null;
};

export type InterviewJourneyPropertiesFragment = {
    __typename?: 'InterviewJourney';
    id: string;
    consent?: ApprovalState | null;
    consent_log?: string | null;
    consent_mail?: string | null;
    consent_ticket: string;
    created_at?: any | null;
    incentive?: ApprovalState | null;
    interview?: ApprovalState | null;
    interview_mail?: string | null;
    interview_status: string;
    workflow: string;
};

export type PanelPropertiesFragment = {
    __typename?: 'Panel';
    id: string;
    created_at: any;
    participantCount: number;
    projectCount: number;
    screening_document?: string | null;
    title: string;
};

export type ParticipantPropertiesFragment = {
    __typename?: 'Participant';
    anonymous: boolean;
    consent_mail?: string | null;
    consent_panel?: ApprovalState | null;
    consent_ticket: string;
    created_at: any;
    email: string;
    hasActiveInterview?: boolean | null;
    hasInterview?: boolean | null;
    id: string;
    interviewJourneyCount?: number | null;
    interview_mail?: string | null;
    lastname: string;
    notes?: any | null;
    phone: string;
    screening?: ApprovalState | null;
    surname: string;
    workflow: string;
};

export type ProjectPropertiesFragment = {
    __typename?: 'Project';
    id: string;
    title: string;
    description?: any | null;
    start_date: string;
    end_date: string;
    screening_document?: string | null;
    consent_intro?: string | null;
    videoCallUrl?: string | null;
    participantCount: number;
    created_at: any;
};

export type UserPropertiesFragment = {
    __typename?: 'User';
    color?: string | null;
    created_at: any;
    email: string;
    id: string;
    lastname: string;
    surname: string;
    title: string;
    type: UserType;
};

export type AnonymizeParticipantMutationVariables = Exact<{
    panelId: Scalars['ID']['input'];
    participantId: Scalars['ID']['input'];
}>;

export type AnonymizeParticipantMutation = {
    __typename?: 'Mutation';
    anonymizeParticipant: {
        __typename?: 'Participant';
        anonymous: boolean;
        consent_mail?: string | null;
        consent_panel?: ApprovalState | null;
        consent_ticket: string;
        created_at: any;
        email: string;
        hasActiveInterview?: boolean | null;
        hasInterview?: boolean | null;
        id: string;
        interviewJourneyCount?: number | null;
        interview_mail?: string | null;
        lastname: string;
        notes?: any | null;
        phone: string;
        screening?: ApprovalState | null;
        surname: string;
        workflow: string;
    };
};

export type AssignProjectParticipantsMutationVariables = Exact<{
    projectId: Scalars['ID']['input'];
    participants: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AssignProjectParticipantsMutation = {
    __typename?: 'Mutation';
    assignParticipants: {
        __typename?: 'Project';
        id: string;
        title: string;
        description?: any | null;
        start_date: string;
        end_date: string;
        screening_document?: string | null;
        consent_intro?: string | null;
        videoCallUrl?: string | null;
        participantCount: number;
        created_at: any;
        interviewJourneys: Array<{ __typename?: 'InterviewJourney'; id: string }>;
    };
};

export type CreatePanelMutationVariables = Exact<{
    panelData: PanelInput;
}>;

export type CreatePanelMutation = {
    __typename?: 'Mutation';
    createPanel: {
        __typename?: 'Panel';
        id: string;
        created_at: any;
        participantCount: number;
        projectCount: number;
        screening_document?: string | null;
        title: string;
    };
};

export type CreateParticipantMutationVariables = Exact<{
    panelId: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['String']['input']>;
    participantData: ParticipantInput;
}>;

export type CreateParticipantMutation = {
    __typename?: 'Mutation';
    createParticipant: {
        __typename?: 'Participant';
        id: string;
        surname: string;
        lastname: string;
        email: string;
        phone: string;
        panel: { __typename?: 'Panel'; id: string };
        interviewJourneys: Array<{ __typename?: 'InterviewJourney'; id: string }>;
    };
};

export type CreateProjectMutationVariables = Exact<{
    panelId: Scalars['ID']['input'];
    projectData: ProjectInput;
}>;

export type CreateProjectMutation = {
    __typename?: 'Mutation';
    createProject: {
        __typename?: 'Project';
        id: string;
        title: string;
        description?: any | null;
        start_date: string;
        end_date: string;
        screening_document?: string | null;
        consent_intro?: string | null;
        videoCallUrl?: string | null;
        participantCount: number;
        created_at: any;
    };
};

export type CreateUserMutationVariables = Exact<{
    userData: UserInput;
}>;

export type CreateUserMutation = {
    __typename?: 'Mutation';
    createUser: {
        __typename?: 'User';
        color?: string | null;
        created_at: any;
        email: string;
        id: string;
        lastname: string;
        surname: string;
        title: string;
        type: UserType;
    };
};

export type DeleteInterviewJourneyMutationVariables = Exact<{
    interviewJourneyId: Scalars['ID']['input'];
}>;

export type DeleteInterviewJourneyMutation = {
    __typename?: 'Mutation';
    deleteInterviewJourney: { __typename?: 'InterviewJourney'; id: string };
};

export type DeleteParticipantMutationVariables = Exact<{
    panelId: Scalars['ID']['input'];
    participantId: Scalars['ID']['input'];
}>;

export type DeleteParticipantMutation = {
    __typename?: 'Mutation';
    deleteParticipant: { __typename?: 'DeleteResponse'; success: boolean };
};

export type GetBookingQueryVariables = Exact<{
    bookingId: Scalars['ID']['input'];
}>;

export type GetBookingQuery = {
    __typename?: 'Query';
    booking: {
        __typename?: 'Booking';
        id: string;
        uid?: string | null;
        sequence: number;
        title?: string | null;
        description?: string | null;
        start?: any | null;
        end?: any | null;
        status?: BookingStatus | null;
        videoCallUrl?: string | null;
        created_at: any;
        interviewJourney?: { __typename?: 'InterviewJourney'; id: string } | null;
        organizer: { __typename?: 'User'; id: string };
        attendeeParticipants: Array<{ __typename?: 'Participant'; id: string }>;
        attendeeUsers: Array<{ __typename?: 'User'; id: string }>;
    };
};

export type GetPanelQueryVariables = Exact<{
    panelId: Scalars['ID']['input'];
}>;

export type GetPanelQuery = {
    __typename?: 'Query';
    panel: {
        __typename?: 'Panel';
        id: string;
        created_at: any;
        participantCount: number;
        projectCount: number;
        screening_document?: string | null;
        title: string;
    };
};

export type GetPanelParticipantsQueryVariables = Exact<{
    panelId: Scalars['ID']['input'];
}>;

export type GetPanelParticipantsQuery = {
    __typename?: 'Query';
    panel: {
        __typename?: 'Panel';
        id: string;
        created_at: any;
        participantCount: number;
        projectCount: number;
        screening_document?: string | null;
        title: string;
        participants: Array<{
            __typename?: 'Participant';
            anonymous: boolean;
            consent_mail?: string | null;
            consent_panel?: ApprovalState | null;
            consent_ticket: string;
            created_at: any;
            email: string;
            hasActiveInterview?: boolean | null;
            hasInterview?: boolean | null;
            id: string;
            interviewJourneyCount?: number | null;
            interview_mail?: string | null;
            lastname: string;
            notes?: any | null;
            phone: string;
            screening?: ApprovalState | null;
            surname: string;
            workflow: string;
        }>;
    };
};

export type GetPanelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPanelsQuery = {
    __typename?: 'Query';
    panels: Array<{
        __typename?: 'Panel';
        id: string;
        created_at: any;
        participantCount: number;
        projectCount: number;
        screening_document?: string | null;
        title: string;
    }>;
};

export type GetParticipantQueryVariables = Exact<{
    participantId: Scalars['ID']['input'];
}>;

export type GetParticipantQuery = {
    __typename?: 'Query';
    participant: {
        __typename?: 'Participant';
        anonymous: boolean;
        consent_mail?: string | null;
        consent_panel?: ApprovalState | null;
        consent_ticket: string;
        created_at: any;
        email: string;
        hasActiveInterview?: boolean | null;
        hasInterview?: boolean | null;
        id: string;
        interviewJourneyCount?: number | null;
        interview_mail?: string | null;
        lastname: string;
        notes?: any | null;
        phone: string;
        screening?: ApprovalState | null;
        surname: string;
        workflow: string;
        panel: {
            __typename?: 'Panel';
            id: string;
            created_at: any;
            participantCount: number;
            projectCount: number;
            screening_document?: string | null;
            title: string;
        };
        bookings: Array<{
            __typename?: 'Booking';
            id: string;
            uid?: string | null;
            sequence: number;
            title?: string | null;
            description?: string | null;
            start?: any | null;
            end?: any | null;
            status?: BookingStatus | null;
            videoCallUrl?: string | null;
            created_at: any;
        }>;
        interviewJourneys: Array<{
            __typename?: 'InterviewJourney';
            id: string;
            consent?: ApprovalState | null;
            consent_log?: string | null;
            consent_mail?: string | null;
            consent_ticket: string;
            created_at?: any | null;
            incentive?: ApprovalState | null;
            interview?: ApprovalState | null;
            interview_mail?: string | null;
            interview_status: string;
            workflow: string;
            project: {
                __typename?: 'Project';
                id: string;
                title: string;
                description?: any | null;
                start_date: string;
                end_date: string;
                screening_document?: string | null;
                consent_intro?: string | null;
                videoCallUrl?: string | null;
                participantCount: number;
                created_at: any;
            };
        }>;
        audit: Array<{
            __typename?: 'Audit';
            created_at: any;
            entity?: string | null;
            entityId?: number | null;
            id: string;
            message: string;
            participantId?: number | null;
            relatedEntity?: string | null;
            relatedEntityId?: number | null;
            type: string;
            typeId?: string | null;
            userId?: number | null;
        }>;
    };
};

export type GetProjectInterviewJourneysQueryVariables = Exact<{
    projectId: Scalars['ID']['input'];
}>;

export type GetProjectInterviewJourneysQuery = {
    __typename?: 'Query';
    project: {
        __typename?: 'Project';
        id: string;
        title: string;
        description?: any | null;
        start_date: string;
        end_date: string;
        screening_document?: string | null;
        consent_intro?: string | null;
        videoCallUrl?: string | null;
        participantCount: number;
        created_at: any;
        panel: {
            __typename?: 'Panel';
            id: string;
            created_at: any;
            participantCount: number;
            projectCount: number;
            screening_document?: string | null;
            title: string;
        };
        interviewJourneys: Array<{
            __typename?: 'InterviewJourney';
            id: string;
            consent?: ApprovalState | null;
            consent_log?: string | null;
            consent_mail?: string | null;
            consent_ticket: string;
            created_at?: any | null;
            incentive?: ApprovalState | null;
            interview?: ApprovalState | null;
            interview_mail?: string | null;
            interview_status: string;
            workflow: string;
            participant: {
                __typename?: 'Participant';
                anonymous: boolean;
                consent_mail?: string | null;
                consent_panel?: ApprovalState | null;
                consent_ticket: string;
                created_at: any;
                email: string;
                hasActiveInterview?: boolean | null;
                hasInterview?: boolean | null;
                id: string;
                interviewJourneyCount?: number | null;
                interview_mail?: string | null;
                lastname: string;
                notes?: any | null;
                phone: string;
                screening?: ApprovalState | null;
                surname: string;
                workflow: string;
            };
            booking?: {
                __typename?: 'Booking';
                id: string;
                uid?: string | null;
                sequence: number;
                title?: string | null;
                description?: string | null;
                start?: any | null;
                end?: any | null;
                status?: BookingStatus | null;
                videoCallUrl?: string | null;
                created_at: any;
                organizer: {
                    __typename?: 'User';
                    color?: string | null;
                    created_at: any;
                    email: string;
                    id: string;
                    lastname: string;
                    surname: string;
                    title: string;
                    type: UserType;
                };
                attendeeUsers: Array<{
                    __typename?: 'User';
                    color?: string | null;
                    created_at: any;
                    email: string;
                    id: string;
                    lastname: string;
                    surname: string;
                    title: string;
                    type: UserType;
                }>;
                attendeeParticipants: Array<{
                    __typename?: 'Participant';
                    id: string;
                    surname: string;
                    lastname: string;
                    email: string;
                    phone: string;
                }>;
                interviewJourney?: { __typename?: 'InterviewJourney'; id: string } | null;
            } | null;
        }>;
    };
};

export type GetProjectsQueryVariables = Exact<{
    panelId: Scalars['ID']['input'];
}>;

export type GetProjectsQuery = {
    __typename?: 'Query';
    projects: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        description?: any | null;
        start_date: string;
        end_date: string;
        screening_document?: string | null;
        consent_intro?: string | null;
        videoCallUrl?: string | null;
        participantCount: number;
        created_at: any;
    }>;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
    __typename?: 'Query';
    users: Array<{
        __typename?: 'User';
        color?: string | null;
        created_at: any;
        email: string;
        id: string;
        lastname: string;
        surname: string;
        title: string;
        type: UserType;
    }>;
};

export type TriggerInterviewWorkflowMutationVariables = Exact<{
    interviewJourneyId: Scalars['ID']['input'];
    eventName: Scalars['String']['input'];
    workflowData: InterviewWorkflowInput;
}>;

export type TriggerInterviewWorkflowMutation = {
    __typename?: 'Mutation';
    triggerInterviewWorkflow: {
        __typename?: 'InterviewJourney';
        id: string;
        consent?: ApprovalState | null;
        consent_log?: string | null;
        consent_mail?: string | null;
        consent_ticket: string;
        created_at?: any | null;
        incentive?: ApprovalState | null;
        interview?: ApprovalState | null;
        interview_mail?: string | null;
        interview_status: string;
        workflow: string;
    };
};

export type TriggerOnboardingWorkflowMutationVariables = Exact<{
    participantId: Scalars['ID']['input'];
    eventName: Scalars['String']['input'];
    workflowData: OnboardingWorkflowInput;
}>;

export type TriggerOnboardingWorkflowMutation = {
    __typename?: 'Mutation';
    triggerOnboardingWorkflow: {
        __typename?: 'Participant';
        anonymous: boolean;
        consent_mail?: string | null;
        consent_panel?: ApprovalState | null;
        consent_ticket: string;
        created_at: any;
        email: string;
        hasActiveInterview?: boolean | null;
        hasInterview?: boolean | null;
        id: string;
        interviewJourneyCount?: number | null;
        interview_mail?: string | null;
        lastname: string;
        notes?: any | null;
        phone: string;
        screening?: ApprovalState | null;
        surname: string;
        workflow: string;
    };
};

export type UpdatePanelMutationVariables = Exact<{
    panelId: Scalars['String']['input'];
    panelData: PanelInput;
}>;

export type UpdatePanelMutation = {
    __typename?: 'Mutation';
    updatePanel: {
        __typename?: 'Panel';
        id: string;
        created_at: any;
        participantCount: number;
        projectCount: number;
        screening_document?: string | null;
        title: string;
    };
};

export type UpdateParticipantMutationVariables = Exact<{
    participantId: Scalars['ID']['input'];
    participantData: ParticipantInput;
}>;

export type UpdateParticipantMutation = {
    __typename?: 'Mutation';
    updateParticipant: {
        __typename?: 'Participant';
        anonymous: boolean;
        consent_mail?: string | null;
        consent_panel?: ApprovalState | null;
        consent_ticket: string;
        created_at: any;
        email: string;
        hasActiveInterview?: boolean | null;
        hasInterview?: boolean | null;
        id: string;
        interviewJourneyCount?: number | null;
        interview_mail?: string | null;
        lastname: string;
        notes?: any | null;
        phone: string;
        screening?: ApprovalState | null;
        surname: string;
        workflow: string;
    };
};

export type UpdateProjectMutationVariables = Exact<{
    projectId: Scalars['ID']['input'];
    projectData: ProjectInput;
}>;

export type UpdateProjectMutation = {
    __typename?: 'Mutation';
    updateProject: {
        __typename?: 'Project';
        id: string;
        title: string;
        description?: any | null;
        start_date: string;
        end_date: string;
        screening_document?: string | null;
        consent_intro?: string | null;
        videoCallUrl?: string | null;
        participantCount: number;
        created_at: any;
    };
};

export type UpdateUserMutationVariables = Exact<{
    userId: Scalars['String']['input'];
    userData: UserInput;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateUser: {
        __typename?: 'User';
        color?: string | null;
        created_at: any;
        email: string;
        id: string;
        lastname: string;
        surname: string;
        title: string;
        type: UserType;
    };
};

export const AuditPropertiesFragmentDoc = gql`
    fragment AuditProperties on Audit {
        created_at
        entity
        entityId
        id
        message
        participantId
        relatedEntity
        relatedEntityId
        type
        typeId
        userId
    }
`;
export const BookingPropertiesFragmentDoc = gql`
    fragment BookingProperties on Booking {
        id
        uid
        sequence
        title
        description
        start
        end
        status
        videoCallUrl
        created_at
    }
`;
export const UserPropertiesFragmentDoc = gql`
    fragment UserProperties on User {
        color
        created_at
        email
        id
        lastname
        surname
        title
        type
    }
`;
export const BookingRelationsFragmentDoc = gql`
    fragment BookingRelations on Booking {
        organizer {
            ...UserProperties
        }
        attendeeUsers {
            ...UserProperties
        }
        attendeeParticipants {
            id
            surname
            lastname
            email
            phone
        }
        interviewJourney {
            id
        }
    }
    ${UserPropertiesFragmentDoc}
`;
export const InterviewJourneyPropertiesFragmentDoc = gql`
    fragment InterviewJourneyProperties on InterviewJourney {
        id
        consent
        consent_log
        consent_mail
        consent_ticket
        created_at
        id
        incentive
        interview
        interview_mail
        interview_status
        workflow
    }
`;
export const PanelPropertiesFragmentDoc = gql`
    fragment PanelProperties on Panel {
        id
        created_at
        participantCount
        projectCount
        screening_document
        title
    }
`;
export const ParticipantPropertiesFragmentDoc = gql`
    fragment ParticipantProperties on Participant {
        anonymous
        consent_mail
        consent_panel
        consent_ticket
        created_at
        email
        hasActiveInterview
        hasInterview
        id
        interviewJourneyCount
        interview_mail
        lastname
        notes
        phone
        screening
        surname
        workflow
    }
`;
export const ProjectPropertiesFragmentDoc = gql`
    fragment ProjectProperties on Project {
        id
        title
        description
        start_date
        end_date
        screening_document
        consent_intro
        videoCallUrl
        participantCount
        created_at
    }
`;
export const AnonymizeParticipantDocument = gql`
    mutation AnonymizeParticipant($panelId: ID!, $participantId: ID!) {
        anonymizeParticipant(panelId: $panelId, participantId: $participantId) {
            ...ParticipantProperties
        }
    }
    ${ParticipantPropertiesFragmentDoc}
`;
export type AnonymizeParticipantMutationFn = Apollo.MutationFunction<
    AnonymizeParticipantMutation,
    AnonymizeParticipantMutationVariables
>;

/**
 * __useAnonymizeParticipantMutation__
 *
 * To run a mutation, you first call `useAnonymizeParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnonymizeParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anonymizeParticipantMutation, { data, loading, error }] = useAnonymizeParticipantMutation({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useAnonymizeParticipantMutation(
    baseOptions?: Apollo.MutationHookOptions<AnonymizeParticipantMutation, AnonymizeParticipantMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AnonymizeParticipantMutation, AnonymizeParticipantMutationVariables>(
        AnonymizeParticipantDocument,
        options
    );
}
export type AnonymizeParticipantMutationHookResult = ReturnType<typeof useAnonymizeParticipantMutation>;
export type AnonymizeParticipantMutationResult = Apollo.MutationResult<AnonymizeParticipantMutation>;
export type AnonymizeParticipantMutationOptions = Apollo.BaseMutationOptions<
    AnonymizeParticipantMutation,
    AnonymizeParticipantMutationVariables
>;
export const AssignProjectParticipantsDocument = gql`
    mutation AssignProjectParticipants($projectId: ID!, $participants: [ID!]!) {
        assignParticipants(projectId: $projectId, participants: $participants) {
            ...ProjectProperties
            interviewJourneys {
                id
            }
        }
    }
    ${ProjectPropertiesFragmentDoc}
`;
export type AssignProjectParticipantsMutationFn = Apollo.MutationFunction<
    AssignProjectParticipantsMutation,
    AssignProjectParticipantsMutationVariables
>;

/**
 * __useAssignProjectParticipantsMutation__
 *
 * To run a mutation, you first call `useAssignProjectParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProjectParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProjectParticipantsMutation, { data, loading, error }] = useAssignProjectParticipantsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      participants: // value for 'participants'
 *   },
 * });
 */
export function useAssignProjectParticipantsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AssignProjectParticipantsMutation,
        AssignProjectParticipantsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AssignProjectParticipantsMutation, AssignProjectParticipantsMutationVariables>(
        AssignProjectParticipantsDocument,
        options
    );
}
export type AssignProjectParticipantsMutationHookResult = ReturnType<typeof useAssignProjectParticipantsMutation>;
export type AssignProjectParticipantsMutationResult = Apollo.MutationResult<AssignProjectParticipantsMutation>;
export type AssignProjectParticipantsMutationOptions = Apollo.BaseMutationOptions<
    AssignProjectParticipantsMutation,
    AssignProjectParticipantsMutationVariables
>;
export const CreatePanelDocument = gql`
    mutation CreatePanel($panelData: PanelInput!) {
        createPanel(panelData: $panelData) {
            ...PanelProperties
        }
    }
    ${PanelPropertiesFragmentDoc}
`;
export type CreatePanelMutationFn = Apollo.MutationFunction<CreatePanelMutation, CreatePanelMutationVariables>;

/**
 * __useCreatePanelMutation__
 *
 * To run a mutation, you first call `useCreatePanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPanelMutation, { data, loading, error }] = useCreatePanelMutation({
 *   variables: {
 *      panelData: // value for 'panelData'
 *   },
 * });
 */
export function useCreatePanelMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePanelMutation, CreatePanelMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreatePanelMutation, CreatePanelMutationVariables>(CreatePanelDocument, options);
}
export type CreatePanelMutationHookResult = ReturnType<typeof useCreatePanelMutation>;
export type CreatePanelMutationResult = Apollo.MutationResult<CreatePanelMutation>;
export type CreatePanelMutationOptions = Apollo.BaseMutationOptions<CreatePanelMutation, CreatePanelMutationVariables>;
export const CreateParticipantDocument = gql`
    mutation CreateParticipant($panelId: String!, $projectId: String, $participantData: ParticipantInput!) {
        createParticipant(panelId: $panelId, projectId: $projectId, participantData: $participantData) {
            id
            surname
            lastname
            email
            phone
            panel {
                id
            }
            interviewJourneys {
                id
            }
        }
    }
`;
export type CreateParticipantMutationFn = Apollo.MutationFunction<
    CreateParticipantMutation,
    CreateParticipantMutationVariables
>;

/**
 * __useCreateParticipantMutation__
 *
 * To run a mutation, you first call `useCreateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantMutation, { data, loading, error }] = useCreateParticipantMutation({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      projectId: // value for 'projectId'
 *      participantData: // value for 'participantData'
 *   },
 * });
 */
export function useCreateParticipantMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateParticipantMutation, CreateParticipantMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateParticipantMutation, CreateParticipantMutationVariables>(
        CreateParticipantDocument,
        options
    );
}
export type CreateParticipantMutationHookResult = ReturnType<typeof useCreateParticipantMutation>;
export type CreateParticipantMutationResult = Apollo.MutationResult<CreateParticipantMutation>;
export type CreateParticipantMutationOptions = Apollo.BaseMutationOptions<
    CreateParticipantMutation,
    CreateParticipantMutationVariables
>;
export const CreateProjectDocument = gql`
    mutation CreateProject($panelId: ID!, $projectData: ProjectInput!) {
        createProject(panelId: $panelId, projectData: $projectData) {
            ...ProjectProperties
        }
    }
    ${ProjectPropertiesFragmentDoc}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      projectData: // value for 'projectData'
 *   },
 * });
 */
export function useCreateProjectMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
>;
export const CreateUserDocument = gql`
    mutation CreateUser($userData: UserInput!) {
        createUser(userData: $userData) {
            ...UserProperties
        }
    }
    ${UserPropertiesFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteInterviewJourneyDocument = gql`
    mutation DeleteInterviewJourney($interviewJourneyId: ID!) {
        deleteInterviewJourney(interviewJourneyId: $interviewJourneyId) {
            id
        }
    }
`;
export type DeleteInterviewJourneyMutationFn = Apollo.MutationFunction<
    DeleteInterviewJourneyMutation,
    DeleteInterviewJourneyMutationVariables
>;

/**
 * __useDeleteInterviewJourneyMutation__
 *
 * To run a mutation, you first call `useDeleteInterviewJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInterviewJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInterviewJourneyMutation, { data, loading, error }] = useDeleteInterviewJourneyMutation({
 *   variables: {
 *      interviewJourneyId: // value for 'interviewJourneyId'
 *   },
 * });
 */
export function useDeleteInterviewJourneyMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteInterviewJourneyMutation, DeleteInterviewJourneyMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteInterviewJourneyMutation, DeleteInterviewJourneyMutationVariables>(
        DeleteInterviewJourneyDocument,
        options
    );
}
export type DeleteInterviewJourneyMutationHookResult = ReturnType<typeof useDeleteInterviewJourneyMutation>;
export type DeleteInterviewJourneyMutationResult = Apollo.MutationResult<DeleteInterviewJourneyMutation>;
export type DeleteInterviewJourneyMutationOptions = Apollo.BaseMutationOptions<
    DeleteInterviewJourneyMutation,
    DeleteInterviewJourneyMutationVariables
>;
export const DeleteParticipantDocument = gql`
    mutation DeleteParticipant($panelId: ID!, $participantId: ID!) {
        deleteParticipant(panelId: $panelId, participantId: $participantId) {
            success
        }
    }
`;
export type DeleteParticipantMutationFn = Apollo.MutationFunction<
    DeleteParticipantMutation,
    DeleteParticipantMutationVariables
>;

/**
 * __useDeleteParticipantMutation__
 *
 * To run a mutation, you first call `useDeleteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParticipantMutation, { data, loading, error }] = useDeleteParticipantMutation({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useDeleteParticipantMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteParticipantMutation, DeleteParticipantMutationVariables>(
        DeleteParticipantDocument,
        options
    );
}
export type DeleteParticipantMutationHookResult = ReturnType<typeof useDeleteParticipantMutation>;
export type DeleteParticipantMutationResult = Apollo.MutationResult<DeleteParticipantMutation>;
export type DeleteParticipantMutationOptions = Apollo.BaseMutationOptions<
    DeleteParticipantMutation,
    DeleteParticipantMutationVariables
>;
export const GetBookingDocument = gql`
    query GetBooking($bookingId: ID!) {
        booking(bookingId: $bookingId) {
            ...BookingProperties
            interviewJourney {
                id
            }
            organizer {
                id
            }
            attendeeParticipants {
                id
            }
            attendeeUsers {
                id
            }
        }
    }
    ${BookingPropertiesFragmentDoc}
`;

/**
 * __useGetBookingQuery__
 *
 * To run a query within a React component, call `useGetBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingQuery(
    baseOptions: Apollo.QueryHookOptions<GetBookingQuery, GetBookingQueryVariables> &
        ({ variables: GetBookingQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options);
}
export function useGetBookingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBookingQuery, GetBookingQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options);
}
export function useGetBookingSuspenseQuery(
    baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBookingQuery, GetBookingQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options);
}
export type GetBookingQueryHookResult = ReturnType<typeof useGetBookingQuery>;
export type GetBookingLazyQueryHookResult = ReturnType<typeof useGetBookingLazyQuery>;
export type GetBookingSuspenseQueryHookResult = ReturnType<typeof useGetBookingSuspenseQuery>;
export type GetBookingQueryResult = Apollo.QueryResult<GetBookingQuery, GetBookingQueryVariables>;
export const GetPanelDocument = gql`
    query GetPanel($panelId: ID!) {
        panel(panelId: $panelId) {
            ...PanelProperties
        }
    }
    ${PanelPropertiesFragmentDoc}
`;

/**
 * __useGetPanelQuery__
 *
 * To run a query within a React component, call `useGetPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPanelQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useGetPanelQuery(
    baseOptions: Apollo.QueryHookOptions<GetPanelQuery, GetPanelQueryVariables> &
        ({ variables: GetPanelQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPanelQuery, GetPanelQueryVariables>(GetPanelDocument, options);
}
export function useGetPanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPanelQuery, GetPanelQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPanelQuery, GetPanelQueryVariables>(GetPanelDocument, options);
}
export function useGetPanelSuspenseQuery(
    baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPanelQuery, GetPanelQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetPanelQuery, GetPanelQueryVariables>(GetPanelDocument, options);
}
export type GetPanelQueryHookResult = ReturnType<typeof useGetPanelQuery>;
export type GetPanelLazyQueryHookResult = ReturnType<typeof useGetPanelLazyQuery>;
export type GetPanelSuspenseQueryHookResult = ReturnType<typeof useGetPanelSuspenseQuery>;
export type GetPanelQueryResult = Apollo.QueryResult<GetPanelQuery, GetPanelQueryVariables>;
export const GetPanelParticipantsDocument = gql`
    query GetPanelParticipants($panelId: ID!) {
        panel(panelId: $panelId) {
            ...PanelProperties
            participants {
                ...ParticipantProperties
            }
        }
    }
    ${PanelPropertiesFragmentDoc}
    ${ParticipantPropertiesFragmentDoc}
`;

/**
 * __useGetPanelParticipantsQuery__
 *
 * To run a query within a React component, call `useGetPanelParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPanelParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPanelParticipantsQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useGetPanelParticipantsQuery(
    baseOptions: Apollo.QueryHookOptions<GetPanelParticipantsQuery, GetPanelParticipantsQueryVariables> &
        ({ variables: GetPanelParticipantsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPanelParticipantsQuery, GetPanelParticipantsQueryVariables>(
        GetPanelParticipantsDocument,
        options
    );
}
export function useGetPanelParticipantsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPanelParticipantsQuery, GetPanelParticipantsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPanelParticipantsQuery, GetPanelParticipantsQueryVariables>(
        GetPanelParticipantsDocument,
        options
    );
}
export function useGetPanelParticipantsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<GetPanelParticipantsQuery, GetPanelParticipantsQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetPanelParticipantsQuery, GetPanelParticipantsQueryVariables>(
        GetPanelParticipantsDocument,
        options
    );
}
export type GetPanelParticipantsQueryHookResult = ReturnType<typeof useGetPanelParticipantsQuery>;
export type GetPanelParticipantsLazyQueryHookResult = ReturnType<typeof useGetPanelParticipantsLazyQuery>;
export type GetPanelParticipantsSuspenseQueryHookResult = ReturnType<typeof useGetPanelParticipantsSuspenseQuery>;
export type GetPanelParticipantsQueryResult = Apollo.QueryResult<
    GetPanelParticipantsQuery,
    GetPanelParticipantsQueryVariables
>;
export const GetPanelsDocument = gql`
    query GetPanels {
        panels {
            ...PanelProperties
        }
    }
    ${PanelPropertiesFragmentDoc}
`;

/**
 * __useGetPanelsQuery__
 *
 * To run a query within a React component, call `useGetPanelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPanelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPanelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPanelsQuery(baseOptions?: Apollo.QueryHookOptions<GetPanelsQuery, GetPanelsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPanelsQuery, GetPanelsQueryVariables>(GetPanelsDocument, options);
}
export function useGetPanelsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPanelsQuery, GetPanelsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPanelsQuery, GetPanelsQueryVariables>(GetPanelsDocument, options);
}
export function useGetPanelsSuspenseQuery(
    baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPanelsQuery, GetPanelsQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetPanelsQuery, GetPanelsQueryVariables>(GetPanelsDocument, options);
}
export type GetPanelsQueryHookResult = ReturnType<typeof useGetPanelsQuery>;
export type GetPanelsLazyQueryHookResult = ReturnType<typeof useGetPanelsLazyQuery>;
export type GetPanelsSuspenseQueryHookResult = ReturnType<typeof useGetPanelsSuspenseQuery>;
export type GetPanelsQueryResult = Apollo.QueryResult<GetPanelsQuery, GetPanelsQueryVariables>;
export const GetParticipantDocument = gql`
    query GetParticipant($participantId: ID!) {
        participant(participantId: $participantId) {
            ...ParticipantProperties
            panel {
                ...PanelProperties
            }
            bookings {
                ...BookingProperties
            }
            interviewJourneys {
                ...InterviewJourneyProperties
                project {
                    ...ProjectProperties
                }
            }
            audit {
                ...AuditProperties
            }
        }
    }
    ${ParticipantPropertiesFragmentDoc}
    ${PanelPropertiesFragmentDoc}
    ${BookingPropertiesFragmentDoc}
    ${InterviewJourneyPropertiesFragmentDoc}
    ${ProjectPropertiesFragmentDoc}
    ${AuditPropertiesFragmentDoc}
`;

/**
 * __useGetParticipantQuery__
 *
 * To run a query within a React component, call `useGetParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetParticipantQuery(
    baseOptions: Apollo.QueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables> &
        ({ variables: GetParticipantQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetParticipantQuery, GetParticipantQueryVariables>(GetParticipantDocument, options);
}
export function useGetParticipantLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetParticipantQuery, GetParticipantQueryVariables>(GetParticipantDocument, options);
}
export function useGetParticipantSuspenseQuery(
    baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetParticipantQuery, GetParticipantQueryVariables>(GetParticipantDocument, options);
}
export type GetParticipantQueryHookResult = ReturnType<typeof useGetParticipantQuery>;
export type GetParticipantLazyQueryHookResult = ReturnType<typeof useGetParticipantLazyQuery>;
export type GetParticipantSuspenseQueryHookResult = ReturnType<typeof useGetParticipantSuspenseQuery>;
export type GetParticipantQueryResult = Apollo.QueryResult<GetParticipantQuery, GetParticipantQueryVariables>;
export const GetProjectInterviewJourneysDocument = gql`
    query GetProjectInterviewJourneys($projectId: ID!) {
        project(projectId: $projectId) {
            ...ProjectProperties
            panel {
                ...PanelProperties
            }
            interviewJourneys {
                ...InterviewJourneyProperties
                participant {
                    ...ParticipantProperties
                }
                booking {
                    ...BookingProperties
                    ...BookingRelations
                }
            }
        }
    }
    ${ProjectPropertiesFragmentDoc}
    ${PanelPropertiesFragmentDoc}
    ${InterviewJourneyPropertiesFragmentDoc}
    ${ParticipantPropertiesFragmentDoc}
    ${BookingPropertiesFragmentDoc}
    ${BookingRelationsFragmentDoc}
`;

/**
 * __useGetProjectInterviewJourneysQuery__
 *
 * To run a query within a React component, call `useGetProjectInterviewJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectInterviewJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectInterviewJourneysQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectInterviewJourneysQuery(
    baseOptions: Apollo.QueryHookOptions<GetProjectInterviewJourneysQuery, GetProjectInterviewJourneysQueryVariables> &
        ({ variables: GetProjectInterviewJourneysQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProjectInterviewJourneysQuery, GetProjectInterviewJourneysQueryVariables>(
        GetProjectInterviewJourneysDocument,
        options
    );
}
export function useGetProjectInterviewJourneysLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProjectInterviewJourneysQuery,
        GetProjectInterviewJourneysQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProjectInterviewJourneysQuery, GetProjectInterviewJourneysQueryVariables>(
        GetProjectInterviewJourneysDocument,
        options
    );
}
export function useGetProjectInterviewJourneysSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<GetProjectInterviewJourneysQuery, GetProjectInterviewJourneysQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetProjectInterviewJourneysQuery, GetProjectInterviewJourneysQueryVariables>(
        GetProjectInterviewJourneysDocument,
        options
    );
}
export type GetProjectInterviewJourneysQueryHookResult = ReturnType<typeof useGetProjectInterviewJourneysQuery>;
export type GetProjectInterviewJourneysLazyQueryHookResult = ReturnType<typeof useGetProjectInterviewJourneysLazyQuery>;
export type GetProjectInterviewJourneysSuspenseQueryHookResult = ReturnType<
    typeof useGetProjectInterviewJourneysSuspenseQuery
>;
export type GetProjectInterviewJourneysQueryResult = Apollo.QueryResult<
    GetProjectInterviewJourneysQuery,
    GetProjectInterviewJourneysQueryVariables
>;
export const GetProjectsDocument = gql`
    query GetProjects($panelId: ID!) {
        projects(panelId: $panelId) {
            ...ProjectProperties
        }
    }
    ${ProjectPropertiesFragmentDoc}
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useGetProjectsQuery(
    baseOptions: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables> &
        ({ variables: GetProjectsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export function useGetProjectsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export function useGetProjectsSuspenseQuery(
    baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsSuspenseQueryHookResult = ReturnType<typeof useGetProjectsSuspenseQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
        users {
            ...UserProperties
        }
    }
    ${UserPropertiesFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
    baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const TriggerInterviewWorkflowDocument = gql`
    mutation TriggerInterviewWorkflow(
        $interviewJourneyId: ID!
        $eventName: String!
        $workflowData: InterviewWorkflowInput!
    ) {
        triggerInterviewWorkflow(
            interviewJourneyId: $interviewJourneyId
            eventName: $eventName
            workflowData: $workflowData
        ) {
            ...InterviewJourneyProperties
        }
    }
    ${InterviewJourneyPropertiesFragmentDoc}
`;
export type TriggerInterviewWorkflowMutationFn = Apollo.MutationFunction<
    TriggerInterviewWorkflowMutation,
    TriggerInterviewWorkflowMutationVariables
>;

/**
 * __useTriggerInterviewWorkflowMutation__
 *
 * To run a mutation, you first call `useTriggerInterviewWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerInterviewWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerInterviewWorkflowMutation, { data, loading, error }] = useTriggerInterviewWorkflowMutation({
 *   variables: {
 *      interviewJourneyId: // value for 'interviewJourneyId'
 *      eventName: // value for 'eventName'
 *      workflowData: // value for 'workflowData'
 *   },
 * });
 */
export function useTriggerInterviewWorkflowMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TriggerInterviewWorkflowMutation,
        TriggerInterviewWorkflowMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<TriggerInterviewWorkflowMutation, TriggerInterviewWorkflowMutationVariables>(
        TriggerInterviewWorkflowDocument,
        options
    );
}
export type TriggerInterviewWorkflowMutationHookResult = ReturnType<typeof useTriggerInterviewWorkflowMutation>;
export type TriggerInterviewWorkflowMutationResult = Apollo.MutationResult<TriggerInterviewWorkflowMutation>;
export type TriggerInterviewWorkflowMutationOptions = Apollo.BaseMutationOptions<
    TriggerInterviewWorkflowMutation,
    TriggerInterviewWorkflowMutationVariables
>;
export const TriggerOnboardingWorkflowDocument = gql`
    mutation TriggerOnboardingWorkflow(
        $participantId: ID!
        $eventName: String!
        $workflowData: OnboardingWorkflowInput!
    ) {
        triggerOnboardingWorkflow(participantId: $participantId, eventName: $eventName, workflowData: $workflowData) {
            ...ParticipantProperties
        }
    }
    ${ParticipantPropertiesFragmentDoc}
`;
export type TriggerOnboardingWorkflowMutationFn = Apollo.MutationFunction<
    TriggerOnboardingWorkflowMutation,
    TriggerOnboardingWorkflowMutationVariables
>;

/**
 * __useTriggerOnboardingWorkflowMutation__
 *
 * To run a mutation, you first call `useTriggerOnboardingWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerOnboardingWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerOnboardingWorkflowMutation, { data, loading, error }] = useTriggerOnboardingWorkflowMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      eventName: // value for 'eventName'
 *      workflowData: // value for 'workflowData'
 *   },
 * });
 */
export function useTriggerOnboardingWorkflowMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TriggerOnboardingWorkflowMutation,
        TriggerOnboardingWorkflowMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<TriggerOnboardingWorkflowMutation, TriggerOnboardingWorkflowMutationVariables>(
        TriggerOnboardingWorkflowDocument,
        options
    );
}
export type TriggerOnboardingWorkflowMutationHookResult = ReturnType<typeof useTriggerOnboardingWorkflowMutation>;
export type TriggerOnboardingWorkflowMutationResult = Apollo.MutationResult<TriggerOnboardingWorkflowMutation>;
export type TriggerOnboardingWorkflowMutationOptions = Apollo.BaseMutationOptions<
    TriggerOnboardingWorkflowMutation,
    TriggerOnboardingWorkflowMutationVariables
>;
export const UpdatePanelDocument = gql`
    mutation UpdatePanel($panelId: String!, $panelData: PanelInput!) {
        updatePanel(panelId: $panelId, panelData: $panelData) {
            ...PanelProperties
        }
    }
    ${PanelPropertiesFragmentDoc}
`;
export type UpdatePanelMutationFn = Apollo.MutationFunction<UpdatePanelMutation, UpdatePanelMutationVariables>;

/**
 * __useUpdatePanelMutation__
 *
 * To run a mutation, you first call `useUpdatePanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePanelMutation, { data, loading, error }] = useUpdatePanelMutation({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      panelData: // value for 'panelData'
 *   },
 * });
 */
export function useUpdatePanelMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePanelMutation, UpdatePanelMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePanelMutation, UpdatePanelMutationVariables>(UpdatePanelDocument, options);
}
export type UpdatePanelMutationHookResult = ReturnType<typeof useUpdatePanelMutation>;
export type UpdatePanelMutationResult = Apollo.MutationResult<UpdatePanelMutation>;
export type UpdatePanelMutationOptions = Apollo.BaseMutationOptions<UpdatePanelMutation, UpdatePanelMutationVariables>;
export const UpdateParticipantDocument = gql`
    mutation UpdateParticipant($participantId: ID!, $participantData: ParticipantInput!) {
        updateParticipant(participantId: $participantId, participantData: $participantData) {
            ...ParticipantProperties
        }
    }
    ${ParticipantPropertiesFragmentDoc}
`;
export type UpdateParticipantMutationFn = Apollo.MutationFunction<
    UpdateParticipantMutation,
    UpdateParticipantMutationVariables
>;

/**
 * __useUpdateParticipantMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantMutation, { data, loading, error }] = useUpdateParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      participantData: // value for 'participantData'
 *   },
 * });
 */
export function useUpdateParticipantMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateParticipantMutation, UpdateParticipantMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateParticipantMutation, UpdateParticipantMutationVariables>(
        UpdateParticipantDocument,
        options
    );
}
export type UpdateParticipantMutationHookResult = ReturnType<typeof useUpdateParticipantMutation>;
export type UpdateParticipantMutationResult = Apollo.MutationResult<UpdateParticipantMutation>;
export type UpdateParticipantMutationOptions = Apollo.BaseMutationOptions<
    UpdateParticipantMutation,
    UpdateParticipantMutationVariables
>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($projectId: ID!, $projectData: ProjectInput!) {
        updateProject(projectId: $projectId, projectData: $projectData) {
            ...ProjectProperties
        }
    }
    ${ProjectPropertiesFragmentDoc}
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectData: // value for 'projectData'
 *   },
 * });
 */
export function useUpdateProjectMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: String!, $userData: UserInput!) {
        updateUser(userId: $userId, userData: $userData) {
            ...UserProperties
        }
    }
    ${UserPropertiesFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userData: // value for 'userData'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
