import { apiRequest } from './apiRequest';

type ApprovalState = 'pending' | 'accepted' | 'declined';

type PanelType = {
    id: number;
    title: string;
    screening_document?: string;
    created_at: string;
    projects?: ProjectType[];
    projectCount: number;
    participants?: ParticipantType[];
    participantCount: number;
};

type ParticipantType = {
    id: number;
    anonymous: boolean;
    email: string;
    surname: string;
    lastname: string;
    phone: string;
    workflow?: string;
    screening?: null | ApprovalState;
    consent_panel?: null | ApprovalState;
    consent_ticket: string;
    interviewJourneys?: InterviewJourneyType[];
    panel: PanelType;
    notes?: any | null;
    hasInterview?: boolean;
    hasActiveInterview?: boolean;
    interviewJourneyCount?: number;
    bookings?: BookingType[];
    created_at: string;
};

export type ProjectType = {
    id: number;
    title: string;
    start_date?: string;
    end_date?: string;
    description?: any;
    consent_intro?: string;
    screening_document?: string;
    created_at: string;
    interviewJourneys: InterviewJourneyType[];
    panel: PanelType;
    participantCount: number;
    videoCallUrl?: string;
};

export type InterviewJourneyType = {
    id: number;
    project?: ProjectType;
    participant?: ParticipantType;
    workflow: string;
    consent_ticket: string;
    consent?: null | ApprovalState;
    interview: null | ApprovalState;
    interview_status: 'accepted' | 'declined';
    incentive: null | ApprovalState;
    booking?: BookingType;
    created_at?: string;
};

type UserType = {
    id: number;
    type: 'admin' | 'client';
    title: string;
    email: string;
    surname: string;
    lastname: string;
    color: string;
    bookings?: BookingType[];
};

export type BookingType = {
    id: number;
    title: string;
    start: string;
    end: string;
    organizer?: UserType;

    attendeeUsers?: UserType[];
    attendeeParticipants?: ParticipantType[];

    interviewJourney?: InterviewJourneyType;
    videoCallUrl: string;
    status?: string;
    description?: string;
    uid?: string;
    sequence?: number;
};

// const sendProjectWorkflowEvent = (
//     projectId: number,
//     participantId: number,
//     eventName: string,
//     data?: Record<string, any>
// ) =>
//     apiRequest<ParticipantType>(`admin/interview-journey/workflow/${eventName}`, {
//         method: 'post',
//         body: JSON.stringify({ ...(data ?? {}), projectId, participantId })
//     });

export type ConsentData = {
    consent: { value: 'confirm' | 'decline' };
    futureInterview?: { value: 'confirm' | 'decline' };
    nda: { value: 'confirm' | 'decline' };
    property: { value: 'confirm' | 'decline' };
    username: { value: 'confirm' | 'decline' };
    consentId: string;
    consentDate: string;
    user: { surname: string; lastname: string; email: string };
};

export const getConsent = (ticketId: string) => apiRequest<InterviewJourneyType[]>(`consent/${ticketId}`);
export const sendConsent = (ticketId: string, consentData: ConsentData) =>
    apiRequest<InterviewJourneyType>(`consent/${ticketId}`, {
        method: 'post',
        body: JSON.stringify(consentData)
    });

export const getPanelConsent = (ticketId: string) => apiRequest<InterviewJourneyType[]>(`panel-consent/${ticketId}`);
export const sendPanelConsent = (ticketId: string, consentData: ConsentData) =>
    apiRequest<ParticipantType[]>(`panel-consent/${ticketId}`, {
        method: 'post',
        body: JSON.stringify(consentData)
    });
