import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import translationEN from './translation/en';
// import translationDE from './translation/de';

const translationDE = {
    admin: 'Admin',
    client: 'Kunde',
    color: 'Farbe',
    userTitle: 'Rolle / Titel in der Signatur',
    userType: 'User Kategorie',
    editBooking: 'Terminbuchung',
    'add new participant': 'Neuen Teilnehmer hinzufügen',
    'add panel member': 'Panel User hinzufügen',
    'anonymize participant': 'Mitglied anonymisieren',
    'create new panel': 'Neues Panel erstellen',
    'create new project': 'Neues Projekt',
    'create participant': 'Mitglied Erstellen',
    'current projects': 'Aktuelle Projekte',
    'delete participant': 'Mitglied und Interviews endgültig löschen',
    'interview participants': 'Mitglieder',
    'project history': 'Projekt Historie',
    'project settings': 'Projekt Settings',
    screeningDocument: 'Screening Leitfaden',
    createANewProject: 'Erstelle ein neues Projekt',
    added: 'Hinzugefügt',
    briefing: 'Briefing',
    cancel: 'Abbrechen',
    createPanel: 'Neues Panel erstellen',
    currentProjects: 'Aktuelle Interviewreihen',
    email: 'Email',
    history: 'Historie',
    isRequired: '$t({{what}}) ist verpflichtend',
    lastname: 'Nachname',
    noPanelsAvailable: 'Es sind noch keine Panels verfügbar. Starte damit dir ein neues Panel zu erstellen',
    notes: 'Notizen',
    onboarding: 'Onboarding',
    onboardingStatus: 'Onboarding Status',
    panels: 'Panels',
    panelTitle: 'Panel Titel',
    participants: 'Mitglieder',
    pendingPanelMembers: 'Panel User im Onboarding Prozess',
    personalData: 'Persönliche Daten',
    phone: 'Telefon',
    projects: 'Interviewreihen',
    projectTimeline: 'Projekt Zeitraum',
    projectProgress: 'Projekt Fortschritt',
    save: 'Speichern',
    screeningCriteria: 'Screening Kriterien',
    screeningLink: 'Screening Dokument',
    status: 'Status',
    surname: 'Vorname',
    title: 'Titel',
    videoCallUrl: 'Video Call URL',
    panel: {
        label: 'Panel'
    },
    participant: {
        label: 'Mitglied',
        modalTitle: 'Mitglied',
        showAll: 'Alle Mitglieder anzeigen',
        displayName: '{{surname}}, {{lastname}}'
    },
    project: {
        label: 'Interviewreihe',
        modalTitle: 'Interviewreihe',
        title: 'Titel der Interviewreihe',
        endDate: 'Ende der Interviewreihe',
        showAll: 'Alle Interviewreihen anzeigen',
        details: 'Details der Interviewreihe',
        period: 'Zeitraum'
    },
    workflow: {
        'SCREENING-APPROVE-INTERVIEW': 'Akzeptiere Teilnehmer für Interview',
        'SCREENING-APPROVE-PANEL-ONLY': 'Akzeptiere Teilnehmer für Panel',
        'SCREENING-DECLINE': 'Teilnehmer ablehnen',
        'SEND-PANEL-CONSENT': 'Panel-Consent anfordern',
        // interview
        'REQUEST-CONSENT': 'Interview Consent anfordern',
        'CONSENT-ACCEPT-BOTH': 'Alle Consents akzeptieren',
        'CONSENT-ACCEPT': 'Interview Consent akzeptieren',
        'CONSENT-DECLINE': 'Interview Consent ablehnen',
        'BOOKING-CREATE': 'Teilnehmer zu Interview einladen',
        'BOOKING-UPDATE': 'Meeting aktualisieren',
        'INTERVIEW-ACCEPT': 'Interview war erfolgreich',
        'INTERVIEW-DECLINE': 'Interview abbrechen',
        'INTENCTIVE-PAID': 'Incentive wurde bezahlt',
        'INTENCTIVE-CANCELED': 'Incentive ablehnen'
    },
    workflowState: {
        // onboarding
        screeningPending: 'Screening ausstehend',
        screeningAccepted: 'Screening akzeptiert',
        screeningDeclined: 'Screening abgelehnt',
        sendPanelConsent: 'Panel-Consent anfordern',
        isPanelMember: 'Panel-User',
        isNotPanelMember: 'Kein Panel-User',
        panelConsentPending: 'Warten auf Panel-Consent',
        pendingInterviewConsent: 'Warten auf Interview-Consent',
        onboardingComplete: 'Onboarding vollständig',
        onboardingDeclined: 'Onboarding abgebrochen',
        panel: 'Panel-User',
        // interview
        sendConsent: 'Consent anfordern',
        consentSent: 'Consent ist angefragt',
        consentPending: 'Warten auf Consent-Bestätigung',
        consentReceived: 'Consent erhalten',
        consentAccepted: 'Consent abgegeben',
        consentDeclined: 'Consent abgelehnt',
        consent: 'Consent Status',
        sendInterviewInvite: 'Interview-Einladung verschicken',
        interviewInviteSent: 'Interview-Einladung verschickt',
        confirmInterview: 'Interview bestätigen',
        interviewConfirmed: 'Interview erfolgreich',
        interviewDeclined: 'Interview abgelehnt',
        incentivePending: 'Incentive zu zahlen',
        incentivePaid: 'Incentive bezahlt',
        incentiveCanceled: 'Incentive abgelehnt',
        interviewCompleted: 'Interview erfolgreich beendet',
        interviewCanceled: 'Interview abgebrochen',
        interviewScreeningDeclined: 'Screening abgelehnt',
        done: 'Interview vollständig'
    }
};
const translationEN = {
    admin: 'Admin',
    client: 'Client',
    color: 'Color',
    userTitle: 'Role / Title in signature',
    userType: 'User Category',
    editBooking: 'Book meeting',
    'add new participant': 'Add new participant',
    'add panel member': 'Add Panel Member',
    'anonymize participant': 'Anonymize participant data',
    'create new panel': 'Create new panel',
    'create new project': 'New project',
    'create participant': 'Create new participant',
    'current projects': 'Current Projects',
    'delete participant': 'Delete all participant and its interview data',
    'interview participants': 'Interview Participants',
    'project history': 'Projekt History',
    'project settings': 'Project settings',
    screeningDocument: 'Screening Document',
    added: 'Added',
    briefing: 'Briefing',
    cancel: 'Cancel',
    createANewProject: 'Create a new project',
    createPanel: 'Create a new panel',
    currentProjects: 'Current Projects',
    email: 'email',
    history: 'History',
    isRequired: '{{what}} is required',
    lastname: 'lastname',
    noPanelsAvailable: 'There are currently no panels available. Start by creating your first panel',
    notes: 'Notes',
    onboarding: 'Onboarding',
    onboardingStatus: 'Onboarding status',
    panels: 'Panels',
    participants: 'Participants',
    pendingPanelMembers: 'Pending Panel Members',
    personalData: 'Personal Data',
    phone: 'phone',
    projectTimeline: 'Project timeline',
    projectProgress: 'Project progress',
    projects: 'Projects',
    save: 'Save',
    screeningCriterias: 'Screening criteria',
    screeningLink: 'Screening document',
    status: 'Status',
    surname: 'surname',
    title: 'Title',
    videoCallUrl: 'Video call URL',
    panel: {
        label: 'Panel'
    },
    participant: {
        label: 'Participant',
        modalTitle: 'Participant',
        showAll: 'Show all participants'
    },
    project: {
        label: 'Project',
        modalTitle: 'Project',
        title: 'Project Title',
        endDate: 'End Date',
        startDate: 'Start Date',
        showAll: 'Show all projects',
        details: 'Project details',
        period: 'Period'
    },
    workflow: {
        // onboarding
        'SCREENING-APPROVE-INTERVIEW': 'Approve sreening for interview',
        'SCREENING-APPROVE-PANEL-ONLY': 'Approve screening for panel only',
        'SCREENING-DECLINE': 'Decline participant',
        'SEND-PANEL-CONSENT': 'Request panel consent',
        // interview
        'REQUEST-CONSENT': 'Request consent',
        'CONSENT-ACCEPT-BOTH': 'Accept all consents',
        'CONSENT-ACCEPT': 'Accept interview consent',
        'CONSENT-DECLINE': 'Decline consent',
        'BOOKING-UPDATE': 'Update meeting',
        'BOOKING-CREATE': 'Invite participant to Interview',
        'INTERVIEW-ACCEPT': 'Confirm successful interview',
        'INTERVIEW-DECLINE': 'Cancel interview',
        'INTENCTIVE-PAID': 'Set incentive as paid',
        'INTENCTIVE-CANCELED': 'Cancel incentive'
    },
    workflowState: {
        // onboarding
        screeningPending: 'Screening pending',
        screeningAccepted: 'Screening accepted',
        screeningDeclined: 'Screening declined',
        sendPanelConsent: 'Request Panel Consent',
        isPanelMember: 'Panel member',
        isNotPanelMember: 'Not a panel member',
        panelConsentPending: 'Waiting for panel consent',
        pendingInterviewConsent: 'Waiting for interview consent',
        onboardingComplete: 'Onboarding complete',
        onboardingDeclined: 'Onboarding canceled',
        panel: 'Panel user',
        // interview
        sendConsent: 'Request consent',
        consentSent: 'Consent requested',
        consentPending: 'Waiting for consent',
        consentReceived: 'Consent received',
        consentAccepted: 'Consent accepted',
        consentDeclined: 'Consent declined',
        consent: 'Consent status',
        sendInterviewInvite: 'Send interview invite',
        interviewInviteSent: 'interview invite sent',
        confirmInterview: 'Interview confirmed',
        interviewConfirmed: 'Interview completed',
        interviewDeclined: 'Interview canceled',
        incentivePending: 'Incentive pending',
        incentivePaid: 'Incentive paid',
        incentiveCanceled: 'Incentive declined',
        interviewCompleted: 'Interview completed successfully',
        interviewCanceled: 'Interview canceled',
        interviewScreeningDeclined: 'Screening declined',
        done: 'Interview complete'
    }
};

export const resources = {
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    }
} as const;

i18next.use(initReactI18next).init({
    debug: false,
    lng: 'de',
    resources
});

export default i18next;
