import { CSSProperties, ReactElement, ReactNode } from 'react';
import './brick.scss';
import classNames from 'classnames';

export type BrickProps = {
    color?: CSSProperties['backgroundColor'];
    children: ReactNode;
    fullWidth?: boolean;
    min?: CSSProperties['minWidth'];
    shrink?: boolean;
    variant?: 'default' | 'solid';
};

Brick.Header = BrickHeader;
Brick.Content = BrickContent;
Brick.Footer = BrickFooter;
Brick.Row = BrickRow;
export function Brick({ children, variant = 'default', color = '#fff', fullWidth, min, shrink }: BrickProps) {
    return (
        <div
            className={classNames('brick', `brick--${variant}`, fullWidth && `brick--full-width`)}
            style={{
                // @ts-expect-error unknown css-variable
                '--brick-color-bg': color,
                minWidth: min ? min : undefined,
                flexGrow: shrink ? 0 : undefined
            }}
        >
            {' '}
            {children}{' '}
        </div>
    );
}

export type BrickHeaderProps = {
    children: ReactNode;
};

export function BrickHeader({ children }: BrickHeaderProps) {
    return <div className="brick__header"> {children} </div>;
}

export type BrickFooterProps = {
    centered?: boolean;
    children: ReactNode;
};

export function BrickFooter({ children, centered }: BrickFooterProps) {
    return <div className={classNames('brick__footer', centered && 'brick__footer--centered')}> {children} </div>;
}

export type BrickContentProps = {
    children: ReactNode;
};

export function BrickContent({ children }: BrickContentProps) {
    return <div className="brick__content"> {children} </div>;
}

export type BrickRowProps = {
    fullWidth?: boolean;
    children: ReactElement<BrickProps> | ReactElement<BrickProps>[];
};

export function BrickRow({ children, fullWidth }: BrickRowProps) {
    return <div className={classNames('brick__row', fullWidth && 'brick__row--full-width')}> {children} </div>;
}
