import { MantineColorsTuple, DEFAULT_THEME } from '@mantine/core';

const leefsPrimary: MantineColorsTuple = [
    '#ffeee5',
    '#ffdbce',
    // '#ffb69c',
    '#FFA686',
    '#fe8e65',
    '#fe6c38',
    '#fe571b',
    '#fe4b0c',
    '#e33c00',
    '#cb3300',
    '#b12800'
];

const leefsSecondary: MantineColorsTuple = [
    '#fcf5ea',
    '#efe9de',
    '#dcd1bf',
    '#c7b89d',
    '#b6a380',
    '#ac956d',
    '#a78e62',
    '#927b51',
    '#826d45',
    '#725d36'
];

export const leefsTheme = {
    ...DEFAULT_THEME,
    colors: {
        ...DEFAULT_THEME.colors,
        leefsPrimary,
        leefsSecondary
    },
    primaryColor: 'leefsPrimary',
    black: '#45423C',
    // white: 'rgb(249, 247, 242)'
    white: '#F5F5F5'
};
