import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { Button, Group, Stack, TextInput } from '@mantine/core';
import { PanelInput } from '../userpanel/api';

export type EditPanelFormProps = {
    panel?: PanelInput;
    onSubmit: (panel?: PanelInput) => void;
};

export function EditPanelForm({ panel, onSubmit }: EditPanelFormProps) {
    const { t } = useTranslation();
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            title: panel?.title,
            screening_document: panel?.screening_document
        },
        validate: {
            title: (value) => (value.length > 0 ? null : t('isRequired', { what: 'title' }))
        }
    });
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
                <TextInput label={t('title')} required key={form.key('title')} {...form.getInputProps('title')} />
                <TextInput
                    label={t('screeningLink')}
                    description="Link für das Dokument zu den Screening Fragen für Panel-User"
                    key={form.key('screening_document')}
                    {...form.getInputProps('screening_document')}
                />
            </Stack>
            <Group justify="flex-end" mt="md">
                <Button variant="transparent" onClick={() => onSubmit()}>
                    {t('cancel')}
                </Button>
                <Button type="submit" variant="filled">
                    {t('save')}
                </Button>
            </Group>
        </form>
    );
}
