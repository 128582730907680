import { useCallback, useState } from 'react';

export type OnSubmit<T> = (returnValue?: T) => void;
export type RequestModalResponse<T> = () => Promise<T>;

/**
 * Open a modal and wait for its response data. If modal is canceled, response will
 * be undefined.
 *
 * # Usage
 *
 * ```ts
 * const [isModalOpen, closeModal, requestModalResponse] = useModalResponse<ReturnValue>();
 *
 * async function getInputData() {
 *   const myResponseValue = await requestModalResponse();
 *   // do something with myResponseValue
 * }
 *
 * <Modal opened={isModalOpen} onClose={closeModal}>
 *   <Button onClick={() => onSubmit(myResponseValue)}>submit</Button>
 * </Modal>
 * ```
 */
export function useModalResponse<T>(): [boolean, OnSubmit<T>, RequestModalResponse<T>] {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalOnClose, setOnClose] = useState<(result?) => void>();
    const requestModalResponse = useCallback(async function requestModalResponse<T>(): Promise<T | undefined> {
        return new Promise((resolve) => {
            setIsModalOpen(true);
            setOnClose(() => (result) => {
                setIsModalOpen(false);
                resolve(result);
            });
        });
    }, []);
    return [isModalOpen, modalOnClose, requestModalResponse];
}
