import classNames from 'classnames';
import './simple-table.scss';
import { MouseEventHandler, ReactElement, ReactNode } from 'react';

export type SimpleTableProps = {
    alternate?: boolean;
    children?: ReactElement<SimpleRowProps> | ReactElement<SimpleRowProps>[];
};

SimpleTable.Row = SimpleRow;
SimpleTable.Cell = SimpleCell;
export function SimpleTable({ alternate, children }: SimpleTableProps) {
    return <div className={classNames('simple-table', alternate && 'simple-table--alternate')}> {children}</div>;
}

export type SimpleRowProps = {
    onClick?: MouseEventHandler<HTMLDivElement>;
    children?: ReactNode;
};
function SimpleRow({ onClick, children }: SimpleRowProps) {
    return (
        <div className={classNames('simple-table__row', onClick && 'clickable')} onClick={onClick}>
            {children}
        </div>
    );
}

export type SimpleCellProps = {
    secondary?: boolean;
    title?: string;
    children?: ReactNode;
};
function SimpleCell({ title, secondary, children }: SimpleCellProps) {
    return (
        <div className={classNames('simple-table__cell', secondary && title && 'simple-table__cell--secondary')}>
            {title ? (
                <>
                    <div className={'simple-table__cell-title'}>{title}</div>
                    <div className={'simple-table__cell-value'}>{children}</div>
                </>
            ) : (
                children
            )}
        </div>
    );
}
